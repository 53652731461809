import React from 'react'
import styles from './index.module.css'

const Agreement = () => {
    return (
      <div className={styles.container}>        
        <h1 className={styles.title}>《联每户软件许可及服务协议》</h1>
        <h3>1、重要提示</h3>
        <div className={styles.describe}>
        杭州联每户网络科技有限公司(以下简称“联每户")在此特别提醒用户务必审慎阅读、充分理解《联每户软件许可及服务协议》(下称“本协议"）)的条款内容(特别是粗体下划线标注的内容)。未成年人应在法定监护人陪同下阅读。除非用户接受本协议所有条款，否则用户无权下载、安装或使用本软件及其相关服务。用户的下载、安装、使用、账号获取和登录等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
        </div>
        <h3>2、协议的范围</h3>
        <div className={styles.describe}>
        2.1 本协议是用户与联每户之间关于下载、安装、使用、复制本软件，以及使用联每户相关服务所订立的协议。
        </div>
        <div className={styles.describe}>
        2.2 本协议、本协议附件和与本软件使用的第三方软件或技术相关的协议或其他文件等，共同构成了本软件及服务的完整协议。
        </div>
        <h3>3、关于本软件</h3>
        <div className={styles.describe}>
        联每户是联每户向用户提供的社区服务平台，以文字、图片等多种媒体形式，向用户提供包括但不限于社区公告、幸福驿站、投诉受理、赞赏等内容或功能。
        </div>
        <h3>4、知识产权声明</h3>
        <div className={styles.describe}>
        4.1 软件的一切版权、商标权、专利权、商业秘密等知识产权，以及与软件相关的所有信息内容，包括但不限于︰文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料或电子文档等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护，除涉及第三方授权的软件或技术外，联每户享有上述知识产权。
        </div>
        <div className={styles.describe}>
        4.2 未经联每户书面同意，用户不得为任何营利性或非营利性的目的自行实施、利用、转让或许可任何第三方实施、利用、转让上述知识产权，联每户保留追究上述未经许可行为的权利。
        </div>
        <h3>5、隐私保护声明</h3>
        <div className={styles.describe}>
        5.1 用户同意联每户自动存储用户在使用联每户软件及服务的过程中产生的信息，包括但不限于位置信息、设备信息、日志信息。
        </div>
        <div className={styles.describe}>
        5.2 联每户不会向合作伙伴提供、分享用户个人信息，除非合作伙伴根据联每户的指示并遵循联每户的隐私保护政策以及其他任何相应的保密和安全措施来处理这些信息。
        </div>
        <div className={styles.describe}>
        5.3 在如下情况下，联每户可能会披露用户的个人信息∶(1）事先获得用户的授权;(2)用户使用共享功能;(3)根据法律、法规、法律程序的要求或政府主管部门的强制性要求;(4)以学术研究或公共利益为目的，且不包含任何个人身份识别信息﹔(5)为维护联每户的合法权益，例如查找、预防、处理欺诈或安全方面的问题;(6)符合相关服务条款或使用协议的规定;(7)其他有利于联每户和用户共同利益目的。
        </div>
        <div className={styles.describe}>
        5.4 联每户非常重视对未成年人个人信息的保护。若用户是18周岁以下的未成年人，在使用联每户的软件前，应事先取得用户家长或法定监护人的同意。
        </div>
        <h3>6、用户使用规则</h3>
        <div className={styles.describe}>
        6.1 用户可以从联每户的官方途径获取本软件，也可以从联每户授权的第三方途径获取。如果用户从未经联每户授权的第三方获取本软件或与本软件名称相同的安装程序，联每户无法保证该软件能够正常使用，并对因此给用户造成的损失不予负责。
        </div>
        <div className={styles.describe}>
        6.2 用户在使用本服务前需要注册一个账号，请用户在注册时详尽、准确地提供个人资料，并不断更新注册资料。因注册信息不真实而引起的问题，由用户自行承担相应的后果。
        </div>
        <div className={styles.describe}>
        6.3 账号的所有权归联每户公司所有，用户完成申请注册手续后，仅获得账号的使用权,且该使用权仅属于初始申请注册人。
        </div>
        <div className={styles.describe}>
        6.4 初始申请注册人不得赠与、借用、租用、转让或售卖账号或者以其他方式许可非初始申请注册人使用账号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用账号。
        </div>
        <div className={styles.describe}>
        6.5 用户在使用赞赏功能时，须同意并遵守使用赞赏功能的相关规则，包括但不限于︰
        </div>
        <div className={styles.describe}>
        6.5.1 赞赏功能是指联每户向联每户用户提供的，允许联每户用户自愿向另一用户赠与款项以示鼓励的功能。用户是否赠与，由其自行决定，但赠与后不可撤回;联每户为该功能的顺利实现提供技术支持。
        </div>
        <div className={styles.describe}>
        6.5.2 用户应当在合法合规的前提下使用赞赏功能，不得将赞赏功能用于违反法律法规、政策及违背公序良俗、社会公德或干扰联每户平台正常运营和侵犯其他用户或第三方合法权益的目的。
        </div>
        <div className={styles.describe}>
        6.5.3 联每户在提供技术支持的过程中，将不可避免的产生渠道技术服务成本。联每户有权调整成本比例、决定收取、暂时或永久免收相应服务费用等，联每户将优化运营，竭力降低渠道技术服务成本所占比例。
        </div>
        <div className={styles.describe}>
        6.5.4 用户使用赞赏功能时，应当保证其帐号绑定的银行账户系用户本人所有。因用户错误绑定而导致的本功能使用异常，如赞赏所得无法转账、转账失败、资金丢失、结算延迟、转至错误账户等任何后果和损失，均由用户自行承担。
        </div>
        <div className={styles.describe}>
        6.6 如用户发现账号遭他人非法使用，应立即通知联每户。互联网上不排除因黑客行为或用户的保管疏忽导致账号、密码遭他人非法使用，此类情况与联每户无关。
        </div>
        <div className={styles.describe}>
        6.7 对于用户通过本软件公开发布的任何内容，用户同意联每户在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分)，和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。
        </div>
        <div className={styles.describe}>
        6.8 为了向用户提供有效的服务，本软件使用过程中可能产生数据流量费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。
        </div>
        <div className={styles.describe}>
        6.9 本软件的某些功能可能会让第三方知晓用户的信息，例如∶用户的头像、名字等可公开的个人资料。
        </div> 
        <div className={styles.describe}>
        6.10 用户同意联每户将会尽其商业上的合理努力保障用户在本软件及服务中的数据存储安全，但是，联每户并不能就此提供完全保证，包括但不限于以下情形∶
        </div> 
        <div className={styles.describe}>
        6.10.1 联每户不对用户在本软件及服务中相关数据的删除或储存失败负责;
        </div>
        <div className={styles.describe}>
        6.10.2 联每户有权根据实际情况自行决定单个用户在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本软件及服务中的相关数据;
        </div> 
        <div className={styles.describe}>
        6.10.3 如果用户停止使用本软件及服务或服务被终止或取消，联每户可以从服务器上永久地删除用户的数据。服务停止、终止或取消后，联每户没有义务向用户返还任何数据。
        </div> 
        <div className={styles.describe}>
        6.11 用户在使用本软件及服务时，须自行承担如下来自联每户不可掌控的风险内容，包括但不限于:
        </div> 
        <div className={styles.describe}>
        6.11.1 由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险;
        </div> 
        <div className={styles.describe}>
        6.11.2 用户必须选择与所安装手机相匹配的软件版本，否则，由于软件与手机型号不相匹配所导致的任何问题或损害，均由用户自行承担;
        </div> 
        <div className={styles.describe}>
        6.11.3 用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任。
        </div> <div className={styles.describe}>
        6.12 除非法律允许或联每户书面许可，用户使用本服务过程中不得从事下列行为︰
        </div> 
        <div className={styles.describe}>
        6.12.1 提交、发布虚假信息，或冒充、利用他人名义的;
        </div> 
        <div className={styles.describe}>
        6.12.2 诱导其他用户点击链接页面或分享信息的;
        </div> 
        <div className={styles.describe}>
        6.12.3 虚构事实、隐瞒真相以误导、欺骗他人的;
        </div> 
        <div className={styles.describe}>
        6.12.4 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的;
        </div> 
        <div className={styles.describe}>
        6.12.5 未经联每户书面许可利用账号和任何功能，以及第三方运营平台进行推广或互相推广的;
        </div> 
        <div className={styles.describe}>
        6.12.6 利用账号或本软件及服务从事任何违法犯罪活动的;
        </div> 
        <div className={styles.describe}>
        6.12.7 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的;
        </div> 
        <div className={styles.describe}>
        6.12.8 其他违反法律法规规定、侵犯其他用户合法权益、干扰本软件及服务正常运营或联每户未明示授权的行为。
        </div> 
        <h3>7、信息内容规则</h3>
        <div className={styles.describe}>
        7.1 本条所述信息内容是指用户使用本软件及服务过程中制作、复制、发布、传播的任何内容，包括但不限于账号头像、名字、用户说明等注册信息，或文字、语音、图片等发送、回复、相关链接页面，以及其他使用账号或本软件及服务所产生的内容。
        </div> 
        <div className={styles.describe}>
        7.2 用户不得利用账号或本软件及服务制作、复制、发布、传播如下干扰软件正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于︰
        </div> 
        <div className={styles.describe}>
        7.2.1 发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽或含有任何性或性暗示的、暴力的内容;
        </div> 
        <div className={styles.describe}>
        7.2.2 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容;
        </div> 
        <div className={styles.describe}>
        7.2.3 其他违反法律法规、政策及公序良俗、社会公德或干扰联每户正常运营和侵犯其他用户或第三方合法权益内容的信息。
        </div> 
        <div className={styles.describe}>
        7.3 用户发送或传播的内容应有合法来源，相关内容为用户所有或用户已获得权利人的授权。
        </div> 
        <div className={styles.describe}>
        7.4 用户通过本软件及服务发送或传播的内容(包括但不限于网页、文字、图片、音频、视频、图表等)均由用户自行承担责任。
        </div> 
        <div className={styles.describe}>
        7.5 如果联每户收到权利人通知，主张用户发送或传播的内容侵犯其相关权利的，用户同意联每户有权进行独立判断并采取删除、屏蔽或断开链接等措施。同时，联每户有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止使用，追究法律责任等措施。
        </div> 
        <div className={styles.describe}>
        7.6 为了避免风险，联每户有权随时根据实际运营情况等因素对赞赏收发的限额、频次、方式、赠与款项的提取机制等相关规则进行设定或调整，有权根据实际运营情况等因素保留调整、中断或终止向相关用户提供赞赏服务。
        </div> 
        <div className={styles.describe}>
        7.7 联每户有权对用户的收发赞赏行为进行监控、审核，若用户存在将赞赏功能用于违反法律法规、政策、相关机构规章制度及违背公序良俗、社会公德或干扰联每户平台正常运营和侵犯其他用户或第三方合法权益的目的的行为，联每户有权采取屏蔽、删除、单方通知等措施暂停或终止向用户提供赞赏服务。
        </div> 
        <h3>8、商业广告推广</h3>
        <div className={styles.describe}>
        8.1 用户同意联每户可以在提供服务的过程中自行或由第三方向用户发送广告、推广或宣传信息（包括商业与非商业信息)，其方式和范围可不经向用户特别通知而变更。
        </div> 
        <div className={styles.describe}>
        8.2 用户同意，对联每户服务中出现的广告信息，用户应审慎判断其真实性和可靠性，除法律明确规定外，用户应对依该广告信息进行的交易负责。
        </div> 
        <h3>9、第三方软件和技术</h3>
        <div className={styles.describe}>
        9.1 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同)，这种使用已经获得合法授权。
        </div> 
        <div className={styles.describe}>
        9.2 本软件如果使用了第三方的软件或技术，联每户将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议"、“开源代码许可证"或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，用户应当遵守这些要求。
        </div> 
        <div className={styles.describe}>
        9.3 如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，联每户不承担任何责任。联每户不对第三方软件或技术提供客服支持，若用户需要获取支持，请与第三方联系。
        </div> 
        <h3>10、不可抗力及其他免责事由</h3>
        <div className={styles.describe}>
        10.1 用户理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，联每户将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户造成的损失联每户在法律允许的范围内免责。
        </div> 
        <div className={styles.describe}>
        10.2 在法律允许的范围内，联每户对以下情形导致的服务中断或受阻不承担责任:
        </div> 
        <div className={styles.describe}>
        10.2.1 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏;
        </div> 
        <div className={styles.describe}>
        10.2.2 用户或联每户的电脑软件、系统、硬件和通信线路出现故障;
        </div> 
        <div className={styles.describe}>
        10.2.3 用户操作不当;
        </div> 
        <div className={styles.describe}>
        10.2.4 其他联每户无法控制或合理预见的情形。
        </div> 
        <div className={styles.describe}>
        10.3 用户理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，联每户不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给用户造成的损害负责。这些风险包括但不限于:
        </div> 
        <div className={styles.describe}>
        10.3.1 来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息;
        </div> 
        <div className={styles.describe}>
        10.3.2 因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失;
        </div> 
        <div className={styles.describe}>
        10.3.3 其他因网络信息或用户行为引起的风险。
        </div> 
        <div className={styles.describe}>
        10.3.4 联每户依据本协议约定获得处理违法违规内容的权利，该权利不构成联每户的义务或承诺，联每户不能保证及时发现违法行为或进行相应处理。
        </div> 
        <h3>11、违约责任</h3>
        <div className={styles.describe}>
        11.1 如果用户违反了本协议的约定，相关国家机关或机构可能会对用户提起诉讼、罚款或采取其他制裁措施，并要求联每户给予协助。造成损害的，用户应依法予以赔偿，联每户不承担任何责任。
        </div> 
        <div className={styles.describe}>
        11.2 用户违反本协议约定，导致任何第三方损害的，用户应当独立承担责任﹔联每户因此遭受损失的，用户也应当一并赔偿。
        </div> 
        <div className={styles.describe}>
        11.3 用户理解并同意，联每户有权依合理判断对违反有关法律法规或本协议规定的行为进行追究法律责任，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
        </div> 
        <h3>12、其他</h3>
        <div className={styles.describe}>
        12.1 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律(不包括冲突法)。
        </div> 
        <div className={styles.describe}>
        12.2 用户使用本软件即视为已阅读并同意受本协议的约束，本公司有权随时修改本协议的任何条款，一旦本协议的内容发生变动，本公司将会直接在本公司网站上公布修改之后的协议内容，该公布行为视为本公司已经通知用户修改内容。同时本公司也可通过其他适当方式向用户提示修改内容。本协议条款变更后，如果用户继续使用本软件，即视为已接受修改后的协议。如果用户不接受修改后的协议，应当停止使用本软件。
        </div> 
        <div className={styles.describe}>
        12.3 若用户和联每户之间发生任何纠纷或争议，首先应友好协商解决﹔协商不成的，用户同意将纠纷或争议提交广东省深圳市福田区人民法院管辖。
        </div>
        <div className={styles.describe}>
        12.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
        </div> 
        <div className={styles.describe}>
        12.5 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
        </div> 
        <h1>“联每户"社区使用协议</h1>
        <div className={styles.describe}>
        『联每户社区』是基于真实的邻里关系搭建的交流平台，旨在促进邻里和谐，交流互助。为保障社区温暖、和谐、友爱、高效的互动氛围，特制定《“联每户"社区使用协议》，详情如下，希望大家可以共同维护。
        </div> 
        <h2>一、社区的功能定位</h2>
        <div className={styles.describe}>
        根据用户的不同使用场景，目前社区中包含“帖子”、“主题”、“智慧家庭”、“社区活动”和“聊天”等功能板块。用户可在浏览内容的同时，通过发帖、评论、点赞、报名、聊天等多种方式与其他用户产生互动。
        </div> 
        <h3>1、邻里互动</h3>
        <div className={styles.describe}>
        社区首页置顶位为邻里互动的入口，其中包括“社区消息”、“群聊推荐”、“聊天列表”和“认识邻居”4个部分。
        </div>
        <div className={styles.describe}>
        1.1“社区消息”，实时为用户反馈社区内容互动的信息。
        </div>
        <div className={styles.describe}>
        1.2“群聊推荐”，邻里间基于共同兴趣爱好建立的社群。
        </div>
        <div className={styles.describe}>
        1.3“消息列表”，邻里间的历史聊天列表。
        </div>
        <div className={styles.describe}>
        1.4“认识邻居”，可与自己附近邻居们打招呼。
        </div>
        <h3>2、社区内容</h3>
        <div className={styles.describe}>
        社区内容采用信息流的展现方式，其中包括“帖子、“智慧家庭”、“活动”和“主题”4个版块。通过智能的排序的算法，致力于让每个用户都能浏览到更丰富、更优质、更新鲜的内容。
        </div>
        <div className={styles.describe}>
        2.1“帖子”版块，是邻居们分享生活的栏目。用户可在此浏览或发布帖子，与其他用户建立互动。
        </div>
        <div className={styles.describe}>
        2.2“智慧家庭”版块，是邻居们交换、赠送或出让个人闲置物品、技能和自产自制商品的栏目。用户发布“智慧家庭”发布的帖子，应当严格遵守《联每户智慧家庭使用协议》的规定。请用户务必仔细阅读并理解《联每户智慧家庭使用协议》。
        </div>
        <div className={styles.describe}>
        2.3“活动”版块，是邻居们报名或发起社区活动的栏目。用户可在此了解社区活动的资讯。
        </div>
        <div className={styles.describe}>
        2.4“主题”版块，是社区中聚合同类内容的栏目，用户可在发帖时选择标签加入相应的主题。
        </div>
        <h2>二、社区内容管理规定及违规用户管理办法</h2>
        <div className={styles.describe}>
        为维护良好的社区氛围，共建规范文明的社区环境，请大家在使用联每户社区的过程中严格遵守社区使用协议的相关规定。对于不符合社区规范，违反国家法律法规、破坏公序良俗及妨碍其他用户正常使用的内容或用户，联每户社区管理员将对其做出相应的处理。
        </div>
        <h3>1、用户昵称和头像管理规定</h3>
        <div className={styles.describe}>
        1.1 用户昵称为用户注册或修改个人信息时自行设定，属用户个人行为。为保障社区和群聊内和谐安定的交流氛围，用户昵称或头像中禁止包含有以下信息，包含但不限于：
        </div>
        <div className={styles.describe}>
        1.1.1 各国国家领导人或其它名人的真实姓名、字号、艺名、笔名等；
        </div>
        <div className={styles.describe}>
        1.1.2 各国国家、政府机构或其它事业性机构的名称；
        </div>
        <div className={styles.describe}>
        1.1.3 不文明、不健康、色情、暴力、侮辱、影射或包含其他不恰当内容的；
        </div>
        <div className={styles.describe}>
        1.1.4 易产生歧义、引起他人误解、混淆视听的；
        </div>
        <div className={styles.describe}>
        1.1.5 侵犯他人名誉权、知识产权等合法权益的；
        </div>
        <div className={styles.describe}>
        1.1.6 商业店铺名称、产品信息、广告信息、第三方联系方式或网站名称等；
        </div>
        <div className={styles.describe}>
        1.2联每户对用户选用的昵称和头像保留不同意的权利，对于昵称或头像违反上述情形的用户，一经发现，即予以警告，并协助修改；对情况严重者将直接禁言帐号，对屡次恶意违规者将永久禁言帐号。
        </div>
        <h3>2、社区内容和聊天内容管理规定</h3>
        <div className={styles.describe}>
        2.1社区发布的内容，应当符合各版块的功能定位。对不符合版块定位的内容，管理员有权对帖子进行相应处理。
        </div>
        <div className={styles.describe}>
        2.2用户在社区、群聊或私聊中发言，应当遵守《“联每户”社区使用协议》，对于不符合规定的内容，管理员有权将其删除，情节严重者将直接禁言帐号。包含但不限于如下内容：
        </div>
        <div className={styles.describe}>
        2.2.1 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        </div>
        <div className={styles.describe}>
        2.2.2 反对宪法所确定的基本原则的，损害国家荣誉和利益的；
        </div>
        <div className={styles.describe}>
        2.2.3 煽动民族仇恨、民族歧视，破坏民族团结的；
        </div>
        <div className={styles.describe}>
        2.2.4 破坏国家宗教政策，宣扬邪教和封建迷信的；
        </div>
        <div className={styles.describe}>
        2.2.5 散布谣言，扰乱社会秩序，破坏社会稳定的；
        </div>
        <div className={styles.describe}>
        2.2.6 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        </div>
        <div className={styles.describe}>
        2.2.7 侮辱或者诽谤他人，恶意的人身攻击、辱骂，侵害他人合法权益的；
        </div>
        <div className={styles.describe}>
        2.2.8 侵害他人名誉权、隐私权、肖像权、知识产权、商业秘密的；
        </div>
        <div className={styles.describe}>
        2.2.9 冒用他人名义发布虚假信息，欺骗、诱导其他用户行为的；
        </div>
        <div className={styles.describe}>
        2.2.10 故意使用相同的昵称或头像涉嫌冒充他人发言的；
        </div>
        <div className={styles.describe}>
        2.2.11 以营利为目的开展专项经营或有专项经营倾向的；
        </div>
        <div className={styles.describe}>
        2.2.12 高频发布重复信息（80%内容一致），造成社区空间资源浪费的；
        </div>
        <div className={styles.describe}>
        2.2.13 含有法律法规或其他规章制度禁止的其他内容或干扰联每户正常运营的；
        </div>
        <div className={styles.describe}>
        2.3 用户内容被处理时，将收到系统发出的通知，通知中会警告用户出现违规操作并告知用户内容被处理的理由；如果用户认为自己的内容不应被处理，可在接到通知后7日内通过申诉渠道提起申诉。
        </div>
        <div className={styles.describe}>
        2.4 对于用户在平台的违规行为，联每户将保留追究相关法律责任的权利。
        </div>
        <h3>3、对违规帐号的处理规则</h3>
        <div className={styles.describe}>
        3.1当用户违反《“联每户”社区使用协议》中的相关约定，“联每户”有权删除其历史发布的内容，视情节对帐号采取封禁措施；同样群聊管理员，也有权将群内多次发布违规内容且警告无效的用户移除群聊，被移除的用户将无法再次加入此群。
        </div>
        <div className={styles.describe}>
        3.2视情节轻重，“联每户”将对违规帐号处以7天、15天、30天或永久禁言的惩罚。对初次禁言的帐号，处以7天禁言；对于二次禁言的帐号，视情节轻重，处以15或30天禁言；对情节严重、警告无效、屡次不改的，将对其永久性禁言。
        </div>
        <div className={styles.describe}>
        3.3 用户帐号被禁言后，将收到系统发出的通知，通知内含有禁言原因。禁言期间，该帐号无法在社区发帖、评论和聊天，其他功能可正常使用。
        </div>
        <h1>联每户智慧家庭版块使用协议</h1>
        <h3>第一条、 立约目的</h3>
        <div className={styles.describe}>
        1.1、 为了规范联每户社区“智慧家庭”板块（以下简称“智慧家庭”）的经营秩序，明确联每户用户在智慧家庭使用中的权利义务，保障各方合法权益，根据《联每户软件许可和使用协议》、《联每户智慧家庭管理规范》等有关内容拟定本协议。
        </div>
        <h3>第二条、 签约主体</h3>
        <div className={styles.describe}>
        2.1、 本协议由杭州联每户网络科技有限公司（以下简称“联每户”）与联每户用户(以下简称“用户”)签署。
        </div>
        <h3>第三条、 协议生效和适用范围</h3>
        <div className={styles.describe}>
        3.1、 您通过联每户页面点击确认或以其他方式选择接受本协议，即表示您与联每户已达成协议并同意接受本协议的全部约定内容。本协议自您点击确认之时起生效，在您使用联每户“智慧家庭”版块服务期间持续有效。
        </div>
        <div className={styles.describe}>
        3.2、 本协议内容包括协议正文、附件以及所有联每户已经发布的或将来可能发布并采取合理途径提前通知您的各类规则、操作流程、行为规范。联每户有权以修改、更新等方式调整本协议和相关规则的内容，相关内容将公布于网站上或以其他方式通知您。您可以明示并通知联每户不愿接受调整后的内容，否则调整后的内容将于公布之日起开始对您生效。
        </div>
        <h3>第四条、 定义</h3>
        <div className={styles.describe}>
        4.1、 智慧家庭：智慧家庭是联每户社区平台下的板块之一，其目的是为联每户用户的闲置物品资产、个人技能、个人自产自制物品提供便捷交易服务，服务内容包括但不限于商品展示、订单生成及追踪、收付款服务。
        </div>
        <div className={styles.describe}>
        4.2、 联每户用户：本协议中指接受联每户“智慧家庭”版块相关服务的用户。
        </div>
        <h3>第五条、 信息提交</h3>
        <div className={styles.describe}>
        5.1、 您在使用联每户“智慧家庭”服务时，必须提供准确的个人资料，包括但不限于个人姓名、联系方式等，如个人资料有任何变动，必须及时更新。您保证您填写及提交的材料、信息是真实、准确、合法、有效的，如联每户发现您的资料造假，有权立即终止本协议并根据本协议或相关规则对您进行处罚。
        </div>
        <h3>第六条、 您的承诺与保证</h3>
        <div className={styles.describe}>
        6.1、 您承诺遵守本协议、《联每户软件许可和用户使用协议》、《联每户智慧家庭管理规范》等所有已公示或将公示的规则、规范及流程。
        </div>
        <div className={styles.describe}>
        6.2、 联每户“智慧家庭”版块用户应独立承担本协议法律责任。如用户不满18周岁，则应承诺其使用“智慧家庭”服务已获得法定监护人的同意，如用户无法独立承担本协议法律责任的，其相应的法律责任由其法定监护人承担。
        </div>
        <div className={styles.describe}>
        6.3、 您需确保自己对在联每户“智慧家庭”版块发布的内容具有合法权利或有效的、完全的授权，不应侵犯他人的肖像权、名誉权、知识产权、隐私权等合法权益，也不会侵犯法人或其他团体的商业秘密等合法权益。如因此引起纠纷，联每户有权直接、立即删除该内容并要求您赔偿全部损失。
        </div>
        <div className={styles.describe}>
        6.4、 您在联每户“智慧家庭”版块发表的言论、发布的信息、作出的行为等应符合国家法律法规的规定、不侵害联每户、联每户关联公司或其他任何第三方主体的任何合法权利。如因此引起纠纷，联每户有权直接删除相关信息，并立即单方终止本协议并要求您赔偿全部损失。
        </div>
        <div className={styles.describe}>
        6.5、 您知悉并了解闲置物品交易纠纷及风险高于普通物品交易，联每户用户需在联每户“智慧家庭”版块内对交易细节进行详细、客观的沟通约定，并遵守相应的约定事项。若因交易约定不详导致的交易纠纷，由联每户用户自行承担风险。
        </div>
        <div className={styles.describe}>
        6.6、 您知悉并了解线下见面交易存在较大的人身财产安全及交易纠纷风险，如因线下见面交易产生纠纷及风险，由联每户用户自行承担。
        </div>
        <div className={styles.describe}>
        6.7、 您不得在联每户“智慧家庭”版块及联每户社区其它板块发表恶意内容，包含评论、商品信息，禁止对任何人进行人身攻击，一经发现，联每户有权删除恶意内容并对攻击帐号进行封禁。
        </div>
        <h3>第七条、 联每户“智慧家庭”版块服务与收费</h3>
        <div className={styles.describe}>
        7.1、 联每户“智慧家庭”版块暂时免费为您提供本协议项下的服务，但并不排除今后就其提供的全部或部分服务或者其他新增服务收取费用，届时联每户将提前公布收费政策及收费规则。
        </div>
        <h3>第八条、 免责及有限责任</h3>
        <div className={styles.describe}>
        8.1、 本协议项下的联每户智慧家庭版块服务将按“现状”和按“可得到”的状态提供，联每户将在现有技术的基础上尽最大努力提供相应的安全措施以保障服务安全和正常运行。但由于可能存在的计算机病毒、网络通讯故障、系统维护等方面的因素以及可能发生的不可抗力事件，联每户在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。
        </div>
        <div className={styles.describe}>
        8.2、 联每户保留变更、中断或终止某些服务的权利，对于因服务调整引起联每户用户数据损失等问题，联每户不承担任何责任。
        </div>
        <div className={styles.describe}>
        8.3、 本协议的签署并不意味着联每户对您的注册身份、资质、履约能力、服务等行为或您自行上传、发布或提交的信息、作品的真实性、准确性、合法性、有效性承担任何明示或暗示的保证或担保等责任，也不意味着联每户成为您与买家之间交易的参与方，联每户亦无义务参与与交易有关的任何纠纷处理等活动。因您的行为导致的任何投诉、纠纷、争议、赔偿等您应以自己的名义独立承担所有法律责任。
        </div>
        <div className={styles.describe}>
        8.4、 您了解并同意，联每户有权在以下情况下向第三方披露您在本站的个人资料 (包括但不限于登陆的账号信息，身份信息，联络方式，使用联每户“智慧家庭”版块产生的数据等)，对此联每户不承担任何责任: a征得您本人允许；b由您本人对外公开；c因您可能侵犯他人权益而被他人投诉， 投诉人依法要求联每户平台提供的；d政府执法部门要求联每户平台提供有关用户的个人资料。
        </div>
        <h3>第九条、 违约责任</h3>
        <div className={styles.describe}>
        9.1、 您明确理解并同意，如因您违反有关法律法规、相关规则或者本协议之规定，使联每户遭受任何损失、受到任何第三方的索赔或者遭受任何行政管理部门的处罚，您应对联每户进行全额赔偿，包括合理的律师费用。
        </div>
        <div className={styles.describe}>
        9.2、 除本协议另有约定外，任何一方违反其于本协议项下的陈述、承诺、保证或义务，而使另一方遭受任何诉讼、纠纷、索赔、处罚等的，违约方应负责解决，使另一方发生任何费用、额外责任或遭受经济损失的，应当负责赔偿。如一方发生违约行为，守约方可以书面通知方式要求违约方在指定的时限内停止违约行为，要求其消除影响。如违约方未能按时停止违约行为，则守约方有权立即终止本协议。
        </div>
        <h3>第十条、 协议终止及转让</h3>
        <div className={styles.describe}>
        10.1、 协议在符合以下任意一项情形时终止：
        </div>
        <div className={styles.describe}>
        10.1.1、自然终止：《联每户智慧家庭版块使用协议》因任何原因终止，则本协议将同时终止；
        </div>
        <div className={styles.describe}>
        10.1.2、通知解除：联每户可提前10个工作日以正式通知的方式终止本协议而无须承担违约责任；
        </div>
        <div className={styles.describe}>
        10.1.3、单方解除权：出现下列情况之一的，联每户有权立刻终止本协议，且按有关规则对您进行处罚：
        </div>
        <div className={styles.describe}>
        （1）您违反本协议中的任何承诺或保证，包括但不限于本协议项下的任何约定；
        </div>
        <div className={styles.describe}>
        （2）您违反联每户的相关条款、协议、规则、通告等相关规定，而被联每户终止提供服务的；
        </div>
        <div className={styles.describe}>
        10.1.4、本协议规定的其他协议终止条件发生或实现，导致本协议终止。
        </div>
        <div className={styles.describe}>
        10.2、 无论本协议因何原因终止，您在协议终止前的行为所导致的任何责任，您必须完全且独立地承担；协议终止后，联每户无需继续提供服务。
        </div>
        <h3>第十一条、其他约定</h3>
        <div className={styles.describe}>
        11.1、 本协议适用中华人民共和国大陆地区法律。因联每户与您就本协议的签订、履行或解释发生争议，各方应努力友好协商解决。如协商不成，您同意由联每户住所地人民法院管辖审理各方的纠纷或争议。
        </div>
      </div>
    )
  }
  
  export default Agreement